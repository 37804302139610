import _ from 'lodash'

export default {
  props: {
    shipper: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      slots: [],
      dates: [],
      selectedDate: null,
      nearestDate: this.$moment(this.shipper.delivery.nearestDeliveryDate).format('YYYY-MM-DD'),
    }
  },
  async created() {
    await this.loadSlots()
    this.transformSlots()
    this.createDays()
    this.transformDays()
    this.setSlot()
    this.setDate()
  },
  methods: {
    createDays() {
      this.dates = []
      const latestSlot = _.maxBy(this.slots, slot => this.$moment(slot.fromDate).valueOf())
      const totalDays = latestSlot.fromDate.diff(this.$moment(), 'days') + 1

      for (let i = 0; i < totalDays; i++) {
        this.dates.push(
          this.$moment()
            .startOf('day')
            .add(i, 'days'),
        )
      }
    },
    async loadSlots() {
      const request = await this.$store.dispatch('cart/FETCH_CALENDAR_SLOTS', {
        since: this.nearestDate,
        shipperId: this.shipper.id,
      })
      this.slots = request
    },
    setDate() {
      if (this.$store.state.cart.input.deliveryTimeSlot) {
        const date = this.dates.find(date =>
          date.slots.find(slot => slot.id == this.$store.state.cart.input.deliveryTimeSlot.id),
        )
        this.selectedDate = date
      }
    },
    setSlot() {
      if (this.$store.state.cart.input.deliveryTimeSlot) {
        const selectedSlot = this.slots.find(slot => slot.id == this.$store.state.cart.input.deliveryTimeSlot.id)
        this.selectSlot(selectedSlot)
      }
    },
    transformSlots() {
      this.slots.forEach(slot => {
        slot.fromDate = this.$moment(slot.from)
        slot.toDate = this.$moment(slot.to)
      })
    },
    transformDays() {
      // Preprocess days
      this.dates.forEach(date => {
        // Filter slots for given date
        this.$set(
          date,
          'slots',
          this.slots.filter(slot => {
            return date.isSame(slot.fromDate, 'day')
          }),
        )
        this.$set(
          date,
          'activeSlots',
          date.slots.filter(slot => {
            return slot.visibility.catalog
          }),
        )
        this.$set(date, 'disabled', date.activeSlots.length === 0)
      })
    },
    selectDate(date) {
      if (!date.disabled && date != this.selectedDate && !date.isBefore(this.nearestDate)) {
        this.selectedDate = date
        this.$store.commit('cart/SET_CART_INPUT_FIELD', {
          field: 'deliveryTimeSlot',
          value: null,
        })
      }
    },
    selectSlot(slot) {
      if (slot && slot.visibility && slot.visibility.catalog) {
        this.$store.commit('cart/SET_CART_INPUT_FIELD', {
          field: 'deliveryTimeSlot',
          value: slot,
        })
      }
    },
  },
  computed: {
    grouppedDates() {
      const groupped = _.groupBy(this.dates, date => {
        return date.format('w gggg')
      })
      return Object.values(groupped)
    },
    selectedSlotId() {
      return this.$store.state.cart.input.deliveryTimeSlot ? this.$store.state.cart.input.deliveryTimeSlot.id : null
    },
  },
}
